(function($){
	if($('.tiny-1_element').length ) {
		var slider = tns({
			container: '.tiny-1_element',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			});
	};

	if($('.tiny_2_elements').length ) {
		var slider = tns({
			container: '.tiny_2_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			responsive: {
				640: {
				  gutter: 20,
				  items: 1
				},
				700: {
				  gutter: 30,
				  items: 2
				}
			  }
		});
	};

	if($('.tiny-3_elements').length ) {
		var slider = tns({
			container: '.tiny-3_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'bottom',
			responsive: {
				640: {
				  gutter: 20,
				  items: 2
				},
				700: {
				  gutter: 30
				},
				1024: {
				  items: 3
				}
			  }
		});
	};
	
	if($('.tiny-5_elements').length ) {
		var slider = tns({
			container: '.tiny-5_elements',
			items: 1,
			controls: false,
			mouseDrag: true,
			nav: true,
			navPosition: 'top',
			responsive: {
				640: {
				  gutter: 20,
				  items: 3
				},
				700: {
				  gutter: 30,
				  items: 4
				},
				1024: {
					items: 5
				}
			  }
		});
	};

})(jQuery);

